<template lang="pug">
  .navigation-layout
    v-layout.my-6(v-if="isFetching")
      v-row.fill-height.ma-0(align='center' justify='center')
        v-progress-circular.ma-auto(:width="2" :size="50" color="primary" indeterminate)
    .content(v-else-if="dataBin")
      div.basic100.py-1(dense flat)
        v-container(style="max-width: 600px")
          v-layout
            v-app-bar-nav-icon.my-auto.ml-n2(small @click="goToPrevious")
              v-icon(color="black") mdi-arrow-left
            .h6.primary500--text.my-auto.ml-2  {{ $t('your_cart') }}
      flow-controller
    v-layout.my-6(v-else)
      v-row.fill-height.ma-0(align='center' justify='center')
        div.mx-auto
          v-img.mx-auto(:src="require('@/assets/images/icon_empty.png')" style="width:80px; height:80px" contain)
          p.caption {{ $t('the_url_no_longer_valid') }}
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FlowController from '@/views/FlowController.vue'

export default {
  name: 'TmpCheckout',
  components: { FlowController },
  props: ['binKey'],
  data: () => ({
    isFetching: false,
    previousLocation: '',
  }),
  computed: {
     ...mapGetters({
      dataBin: 'current/getDataBin',
      currency: 'currency/getCurrency',
      cart: 'cart/getCart'
    })
  },
  mounted () {
    this.$store.commit('cart/setTmpCheckout', true)
    this.fetchDataBin()
  },
  methods: {
    goToPrevious () {
      let data = JSON.parse(JSON.stringify(this.dataBin))
      data.products = JSON.parse(JSON.stringify(this.cart))
      this.$axios.put(`/databin/${data.binKey}/?ttl=300`, data)
        .then(() => {
          location.href = this.previousLocation
        })
        .catch(() => {
          this.showMessage(['error', 'Unable to complete your request. Please try again later'])
        })
    },
    fetchDataBin() {
      this.isFetching = true
      this.$axios.get(`/databin/${this.binKey}/`)
        .then((resp) => {
          this.previousLocation = resp.data.previous_location
          const dataBin = resp.data
          dataBin.binKey = this.binKey
          this.$store.commit('current/updateDataBin', dataBin)
          this.$store.commit('currency/updateCurrency', dataBin.form.company.currency)
          this.$store.commit('language/setLanguage', dataBin.language)
          Vue.prototype.$currency = this.currency
          this.isFetching = false
        })
        .catch(() => {})
    }
  }
}
</script>
